
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                

.u-article {
	// reset floats
	clear: both;
	p,
	ul,
	ol,
	h1,
	h2,
	h3,
	h4,
	h5 {
		// for readability
		max-width: 50rem;
	}
	.InlineMedia,
	.HybridMedia,
	.Lightbox {
		ul,
		ol {
			max-width: none;
		}
	}
	p a:not(.Button) {
		color: darken(saturate($color-link, 10%), 5%);
    	text-decoration-color: lighten($color-link, 6%);
		text-underline-offset: 0.3em;
	}
	table tr th, table tr td {
		padding: $space-sm;
	}
}
.WordPress {
	p,
	ul,
	ol {
		font-family: $default-font-family;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	table,
	table p,
	button,
	.SectionMenu ul {
		font-family: $default-font-family;
	}
	// reset floats
	clear: both;
	p,
	ul,
	ol,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		// for readability
		max-width: 50rem;
	}
	.InlineMedia,
	.HybridMedia,
	.Lightbox {
		ul,
		ol {
			max-width: none;
		}
	}
	p a:not(.Button) {
		color: darken(saturate($color-link, 10%), 5%);
    	text-decoration-color: lighten($color-link, 6%);
		text-underline-offset: 0.3em;
	}
	table tr th, table tr td {
		padding: $space-sm;
	}
}
.Embed {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
}
.Embed iframe {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}
[data-panel][aria-hidden='true'] {
	display: none;
}
.articleNavigation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@include text(4);
	a {
		font-weight: bold;
		align-items: center;
		display: flex;
		gap: $space-sm;
	}
	.Icon {
		@include icon-sm;
	}
	&-prev {
		align-self: flex-start;
	}
	&-next {
		align-self: flex-end;
	}
}
cite {
	font-style: normal;
}
.Definitions,
.Definitions p {
	@include text(2);
	margin-bottom: 0;
}
.Definitions dt {
	font-weight: bold;
	font-style: normal;
	margin: 0 0.5rem 0 0;
}
.Definitions dd {
	margin: 0 0 1rem;
	> * {
		@include text(2);
		//font-family: $body-font-family;
	}
}
.Definitions-seeAlso {
	color: $color-link;
	display: inline-flex;
	align-items: center;
	margin-bottom: 1rem;
}
.Definitions-seeAlso[aria-expanded='true'] {
	margin-bottom: 0;
}
.Definitions-seeAlso[aria-expanded='true'] .Icon--triangleDown {
	transform: scaleY(-1);
}
.Definitions dd[aria-hidden='true'] {
	display: none;
}
.Definitions-legend {
	background-color: #f4f4f4;
}
.Definitions-repeatedTerm {
	display: block;
	font-weight: bold;
}
@include breakpoint(medium) {
	.ReferenceBelow .ToggleUp-panel {
		top: calc(100% + 0.5rem);
		bottom: auto;
	}
	.ReferenceBelow .ToggleUp-panel::before,
	.ReferenceBelow .ToggleUp-panel::after {
		display: none;
	}
}
.Figure {
	@include stack-lg;
	.Figure-contents {
		display: block;
		@include stack-sm;
	}
	.Figure-meta {
		.Figure-title {
		}
		.Figure-caption {
			@include text(2);
			@include stack-sm;
		}
	}
	.Figure-credit {
		@include text(2);
	}
	.Figure-enlargeMeta {
		float: right;
		@include text(2);
		.Icon {
			@include icon-sm;
		}
	}
}
// legend for range maps
.Legend {
	clear: both;
	@include stack-sm;
}
.Legend-group {
	list-style: none;
	margin: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.Legend-item {
	@include text(2);
	@include stack-sm;
	display: flex;
	align-items: center;
}
.Legend-swatch {
	display: block;
	@include inline-sm;
	width: $space-md;
	height: $space-md;
	background-color: $color-black;
	&--yearRound {
		background-color: #aeb0d9;
	}
	&--migration {
		background-color: #f7e983;
	}
	&--breeding {
		background-color: #f5ae8c;
	}
	&--nonBreeding {
		background-color: #9dcce9;
	}
}

.Tooltip {
	color: $color-text !important;
}

.ToggleUp {
	position: relative;
	a {
		color: saturate($color-link, 20%) !important;
	}
}
.ToggleUp-panel {
	@include panelHidden('bottom', auto);
	@include dropShadow(2);
	position: fixed;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-wrap: break-word;
	word-wrap: break-word;
	@include text(3);
	//background-color: $color-highlight;
	background-color: $color-highlight;
	@include inset-md;
	margin-left: 0;
	&[aria-hidden='false'] {
		display: block;
	}
	@include breakpoint(medium) {
		position: absolute;
		width: 28rem;
		top: auto;
		right: auto;
		bottom: calc(100% + 0.5rem); /* positions .5rem above top of link */
		left: 50%; /* positions panel edge in middle of link */
		transform: translate(-50%, 0); /* centers panel over middle of link */
	}
}
.ToggleUp-close {
	margin-bottom: 0;
	@include breakpoint(medium) {
		display: none;
	}
}

.InlineList .is-selected a {
	color: $color-text;
}

.Indicator {
	@include inset-sm;
	text-align: center;
	color: $color-white;
	font-weight: bold;
	@include text(3);
	border-block-end: 1px solid $color-neutral-2;
	&--primary {
		background-color: $color-primary-3;
	}
	&--warning {
		background-color: $color-alert-3;
	}
	&--info {
		background-color: $brand-color-sky-blue-tint-2;
		color: $color-text;
	}
}

.u-md-size0of6 {
	display: none;
}

.Error {
	@include inset-responsive;
	min-height: 60vh;
	display: grid;
	align-items: center;
	.Error-message {
		@include inset-responsive;
		border-radius: $radius-sm;
		background-color: $color-overlay-black;
		color: $color-white;
		@include breakpoint(medium) {
			max-width: 33vw;
		}
	}
}

.MediaCredit--overlay {
	background: $color-overlay-black;
}
a.StatsIcon {
	color: $color-link;
	&:hover,
	&:focus,
	&:active {
		color: $color-link-hover;
	}
}

.CarouselResponsive--showcase .CarouselResponsive-slide img {
	width: 100% !important;
}
.CarouselResponsive--illustrations .CarouselResponsive-slide {
	min-height: 10rem;
}

.CarouselResponsive--centered {
	@include breakpoint(medium) {
		display: flex;
		justify-content: center;
	}
	.CarouselResponsive-slide img {
		@include breakpoint(medium) {
			/*min-width: 10rem;
			min-height: 9rem;
			max-width: 12rem;
			max-height: 11rem;
			*/
		}

	}
	// limit to showing 6
	.CarouselResponsive-slide:nth-child(n + 10) {
		//display: none;
	}
}

#addReference .Dialog-container-body {
	overflow: unset;
}
.Dialog {
	z-index: 1003; // above author toolbar
}

.Button--flex {
	display: flex;
	align-items: center;
	gap: $space-sm;
}

.Button--editLive {
	background-color: $brand-color-red-shade;
	&:hover,
	&:focus {
		background-color: $brand-color-red;
	}
}

.SciName {
	font-style: italic;
}

.RequestTrialForm {
	max-width: 600px;
	margin: auto;
	padding: 1rem;
	background: $color-neutral-0;
	@include dropShadow(2);
}

.Hint--light {
	color: $color-text-muted;
	margin-left: $space-xs;
}

.Button--subscribe {
	background-color: $brand-color-red-shade; // $color-error
	&:hover,
	&:focus {
		background-color: $brand-color-red-shade;
	}
}

.InlineMedia .MediaCredit .MediaCredit-links a span:last-child {
	padding-left: $space-xs;
}

.Heading.Heading--lg {
	@include breakpoint(xlarge) {
		font-size: 4rem;
		line-height: 1;
	}
	@include breakpoint(xxlarge) {
		font-size: 4.3rem;
	}
}

.Heading.Heading--margin {
	margin-block-start: $space-xl;
	margin-block-end: $space-xl;
}

.LogoBOW {
	width: 140px;
	@include breakpoint(medium) {
		width: 360px;
	}
}

.Page-section--neutralDark {
	background-color: $brand-color-sand-dark-3;
}

.Page-section--sandDark {
	background-color: $brand-color-sand-dark-3;
}

.Hero--black {
	.Hero-content {
		background-color: $brand-color-sand-dark-3;
	}
}
// suggest dropdown shouldn't cover stats
.Hero .Hero-content {
	overflow: visible;
	z-index: 1;
}
.StatsIcon {
	@include breakpoint(medium) {
		font-size: 1.75rem;
	}
}

// prevent Heading flex styles from causing chaos with em and span inside
.Table .Heading,
.Figure .Heading,
.Lightbox-galleryTitle {
	display: block;
	em,
	span {
		font-weight: inherit;
		color: inherit;
	}
}

.sidebarNav {
	background-color: $brand-color-sky-blue-tint-2;
}

.Page-section--sandTint1 {
	background-color: $brand-color-sand-tint-1;
}
.Page-section--sand {
	background-color: $brand-color-sand;
}
.Page-section--skyTint1 {
	background-color: $brand-color-sky-blue-tint-1;
}
.Page-section--skyTint2 {
	background-color: $brand-color-sky-blue-tint-2;
}
.Page-section--sky {
	background-color: $brand-color-sky-blue;
}
.Page-section--bottomBorder {
	border-block-end: 1px solid $color-neutral-2;
}

.LogosGrid {
	background-color: $brand-color-sky-blue-tint-1;
	.LogosGrid-item {
		border-radius: $space-sm;
	}
}
.LogosGrid--narrow {
	background-color: $brand-color-sky-blue-tint-2 !important;
}

.SectionMenu--nested .SectionMenu-section-list {
	& > li > span {
		display: flex;
		gap: $space-md;
		align-items: center;
		justify-content: space-between;
		@include inset-squish-md;
	}
}

.labLine {
	width: 50%;
	border-inline-end: 1.5px solid $color-neutral-5;
	height: 5rem;
}
.paddingSides {
	margin: $space-md;
	@include breakpoint(medium) {
		margin: 0;
		padding-inline: $space-lg;
	}
}
.paddingSides--xl {
	margin: $space-md;
	@include breakpoint(medium) {
		margin: 0;
		padding-inline: $space-xl;
	}
}
.articleLine {
	border-inline-start: 1.5px solid $brand-color-sand-8;
	padding-inline-start: $space-md;
	@include stack-lg;
}
.Media--line {
	display: flex;
	gap: $space-sm;
	.Media-caption {
		border-inline-start: 1px solid $brand-color-sand-8;
		padding-inline-start: $space-md;
		.credit {
			@include text(3);
			@include stack-sm;
			display: flex;
			gap: $space-md;
			color: $color-text-muted;
			span {
				border-inline-end: 1px solid $brand-color-sand-8;
				padding-inline-end: $space-md;
				&:last-child {
					border: 0;
				}
			}
		}
		.excerpt {
			@include text(3);
			-webkit-line-clamp: 3;
			line-clamp: 3;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
.Blurb.articleLine .Blurb-excerpt span {
	-webkit-line-clamp: 3;
	line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.Blurb--callout {
	background-color: $brand-color-sky-blue-tint-1;
}
.revisionStatus {
	padding: 2px 4px;
	display: inline;
	background-color: $brand-color-chartreuse-tint-1;
	@include text(1);
	text-transform: uppercase;
	color: $color-text;
}
.revisionStatusPartial {
	padding: 2px 4px;
	display: inline;
	background-color: $brand-color-gold-5;
	@include text(1);
	text-transform: uppercase;
	color: $color-text;
}
.StatsIcon {
	border-inline-start: 1.5px solid $brand-color-sand-shade;
	padding-inline-start: .75rem;
}
.paragraphCentered {
	margin: 0 auto $space-md auto;
	max-width: 40rem;
	text-align: center;
}
.u-md-inset-none {
	@include breakpoint(medium) {
		padding: 0 !important;
	}
}
.Button--toggler {
	display: inline-flex;
	align-items: center;
	gap: $space-xs;
}

.AccountMenu {
	color: $color-text-muted;
	@include text(3);
	font-weight: bold;
	ul {
		list-style: none;
		margin: 0;
	}
	&-section:not(:last-child) {
		padding-block-end: $space-md;
	}
	a,
	span {
		display: flex;
		padding: 0.8rem 1.2rem 0.6rem;
		line-height: 1.25;
	}
	a {
		//color: darken($color-link, 10%);
		color: darken(saturate($color-link, 20%), 5%) !important;
		&:hover,
		&:focus {
			color: $color-link-hover;
			text-decoration: underline;
			background-color: lighten($brand-color-sky-blue-tint-2, 2.5%);
		}
		&.active {
			color: $color-text;
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
	ul ul {
		margin-inline-start: 2.4rem;
		z-index: 1;
		a,
		span {
			display: flex;
			padding: 0.6rem 0 0.4rem;
			font-weight: normal;
			&:hover,
			&:focus {
				background-color: transparent;
			}
		}
	}
	@include breakpoint(medium) {
		li:not(.active) {
			ul {
				display: none;
			}
		}
		li:not(.active):hover,
		li:not(.active):focus {
			position: relative;
			ul {
				margin: 0;
				padding-inline: $space-md;
				padding-block-end: $space-sm;
				display: block;
				position: absolute;
				left: 100%;
				[dir='rtl'] & {
					left: auto;
					right: 100%;
				}
				top: 0;
				min-width: 12rem;
				background-color: lighten($brand-color-sky-blue-tint-2, 3%);
				box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
			}
		}
	}
}

.Header-group .Header-link--buttonPrimary {
	background: $color-secondary-4;
	&:hover,
	&:focus {
		background: $color-secondary-3;
		border-color: $color-secondary-3;
	}
}
.Header-list--dropdown .Header-list-heading .Header-list-heading-label {
	@include breakpoint(medium) {
		@include overrideVisuallyHidden;
		@include text(2);
		margin-inline-start: $space-xs;
		margin-inline-end: $space-xs;
		text-decoration: none;
	}
}
.Header-item--text {
	display: block;
}
.Header-group.Header-group--secondary {
	flex-wrap: wrap;
}

.showForHeaderBreakpoint {
	@media (max-width: 1199px) {
		display: none !important;
	}
}
.hideForHeaderBreakpoint {@include breakpoint(headerBreakpoint){display: none !important;}}

.bannerMsg {
	position: absolute;
	z-index: 1001;
	@include inset-sm;
}
.Footer a {
	text-decoration: underline;
	text-decoration-color: darken($color-neutral-4, 10%);
}
.Footer .Footer-main .Button.Button--highlight {
	background-color: $color-secondary-4;
	&:hover,
	&:focus {
		background-color: $color-secondary-3;
	}
}
.Footer .Footer-main .Button {
	background-color: $color-primary-4;
	text-decoration: none;
	&:hover,
	&:focus {
		background-color: $color-primary-3;
	}
}
.FreeTrial {
	@include inset-responsive;
	gap: $space-md;
	@include breakpoint(medium) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: $space-xl;
	}
	.GridFlex-cell {
		width: 100% !important
	}
}

.u-text-3 p {
	@include text(3);
}

.buttonGroup {
	display: flex;
	gap: $space-md;
}

#headerForPrint {
	display: none;
}

@media print {
  /* All your print styles go here */
  #headerForPrint {
	display: block !important;
  }
  #header,
  #footer,
  .sidebarNav,
  .ActivityBadge,
  .Toolbar-group--right,
  .AuthorToolbar,
  #partnerships,
  .Button--editLive,
  .GoToTop {
    display: none !important;
  }
  #pagegrid {
	display: block !important;
	& > .u-md-size3of6 {
		width: auto !important;
	}
  }
  .Hero {
	display: block !important;
	background-color: #fff !important;
	.Hero-image {
		max-width: 75% !important;
		margin: 0 32px;
	}
  }
  .Hero-content, .Toolbar {
	background-color: #fff !important;
	& * {
		color: $color-black !important;
	}
  }
  .Toolbar {
	margin: 0 32px;
  }
	.u-article p,
	.u-article ul,
	.u-article ol,
	.u-article h1,
	.u-article h2,
	.u-article h3,
	.u-article h4,
	.u-article h5 {
		max-width: none !important;
	}
}

