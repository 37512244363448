
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                





































































































































































































































































.Dialog--ad {
	.Dialog-container {
		outline: 0;
		padding: 0;
		background-color: transparent;
		box-shadow: none;
		position: relative;
		max-width: calc(100vh - 0.5rem);
		max-height: calc(100vw - 0.5rem);
		@include breakpoint(xlarge) {
			max-width: min(900px, calc(100vh - 3rem));
			max-height: min(900px, calc(100vw - 3rem));
		}
	}
	.Dialog-container-close {
		top: -$space-md;
		@include breakpoint(medium) {
			right: $space-sm;
		}
		.Icon {
			fill: $color-white;
		}
	}
	.Dialog-container-body {
		padding: 2rem 1rem;
		@include breakpoint(medium) {
			padding: 2rem;
		}
		overflow-y: visible;
		display: grid;
		align-items: center;
		justify-content: center;
		text-align: center;
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
		}
	}
}
.FullscreenAd-minimized {
	z-index: 1001;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	@include inset-md;
	@include text(3);
	@include colorReverse;
	color: $color-white;
	background-color: $color-primary-5;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: $space-md;
}
.FullscreenAd-minimized-text {
	display: flex;
	gap: $space-sm $space-md;
	flex-wrap: wrap;
}
.Dialog--freeAccess {
	.Dialog-container {
		padding: 0;
		outline: 0;
		overflow: hidden;
		border-radius: 6px;
		.freeAccess {
			display: flex;
			flex-wrap: nowrap;
			.content {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: $space-sm;
				@include inset-md;
				@include breakpoint(medium) {
					background-color: $brand-color-sand-tint-1;
					padding: $space-xl $space-lg;
				}
				.buttonGroup {
					display: flex;
					gap: $space-md;
				}
			}
			.image {
				display: none;
				@include breakpoint(medium) {
					@include inset-md;
					display: grid;
					align-items: center;
					justify-content: center;
					flex: 1;
					background-color: $brand-color-ocean-blue;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	.Dialog-container-close {
		.Icon {
			fill: $color-white;
		}
	}
}
