
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                





















































































































.ActivityBadge--reverse {
	.ActivityBadge-badge {
		color: subdueColor($color-primary-1, true);
	}
	.ActivityBadge-badge--achieved {
		color: $color-primary-2;
	}	
	.ActivityBadge-modal {
		.ActivityBadge-badge {
			color: $color-text-muted;
		}
		.ActivityBadge-badge--achieved {
			color: $color-link;
		}
	}
}
