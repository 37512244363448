
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                




























































































































.HybridMedia {
  max-width: 50rem;
  @include stack-lg;
}
