
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                



























































































.Media {
	cursor: pointer;
	&:hover {
		outline: 1px solid $color-primary-3;
	}
	img {
		max-height: 10rem;
		max-width: 12rem;
		transform: scaleX(-1);
		transition: opacity 0.3s ease;
		opacity: 0;
		&.loaded {
			opacity: 1;
		}
	}
}
