
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                





































































































.AuthorToolbar {
	@include colorReverse;
	background-color: $color-black;
	@include inset-squish-md;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
