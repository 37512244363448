
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                












































.Media {
	img {
		max-height: 6rem;
		max-width: 8rem;
		@include breakpoint(xlarge) {
			max-height: 8rem;
			max-width: 10rem;
		}
		transform: scaleX(-1);
		transition: opacity 0.3s ease;
		opacity: 0;
		&.loaded {
			opacity: 1;
		}
	}
}
