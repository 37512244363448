
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                





































































































































.LatestUpdates {
	.background {
		@include inset-sm;
		background-color: $color-neutral-1;
	}
	.responsiveGrid {
		grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
		& > * {
			flex-basis: 10rem;
		}
	}
	@include breakpoint(medium) {
		.responsiveGrid {
			grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
			& > * {
				flex-basis: 14rem;
			}
		}
	}
	.Media {
		cursor: pointer;
		&:hover {
			outline: 1px solid $color-primary-3;
		}
		img {
			max-width: 7rem;
			max-height: 8rem;
			@include breakpoint(medium) {
				max-width: 10rem;
				max-height: 9rem;
			}
			transform: scaleX(-1);
			transition: opacity 0.3s ease;
			opacity: 0;
			&.loaded {
				opacity: 1;
			}
		}
	}
	.error {
		display: grid;
		place-items: center;
		min-height: 10rem;
	}
}
