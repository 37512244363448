
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                

























































































































































































































































































































































































































body {
	scroll-behavior: smooth;
}
.anchor {
	position: relative;
	a {
		position: absolute;
		top: -6rem;
	}
}
.illustrationCards {
	display: grid;
	width: 100%;
	max-width: 100%;
	grid-auto-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: repeat(7, 6rem);
	gap: $space-md;
	@include breakpoint(medium) {
		gap: $space-lg;
	}
}
.illustrationCard {
	max-width: 14rem;
	border: 4px solid $color-neutral-1;
	&:nth-child(1) {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
	}
	&:nth-child(2) {
		grid-column: 2 / 3;
		grid-row: 2 / 4;
	}
	&:nth-child(3) {
		grid-column: 3 / 4;
		grid-row: 3 / 5;
		display: none;
		@include breakpoint(medium) {
			display: initial;
		}
	}
	&:nth-child(4) {
		grid-column: 2 / 3;
		grid-row: 4 / 6;
	}
	&:nth-child(5) {
		grid-column: 1 / 2;
		grid-row: 5 / 7;
	}
}
.Button--link {
	text-underline-offset: $space-rel-xs;
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-thickness: 0.0625rem;
	text-decoration-color: rgba(44, 117, 201, 0.25);
}
.topBar {
	background-color: $brand-color-warm-black;
	@include inset-md;
	border-block-end: $color-neutral-4;
	position: sticky;
	top: 0;
	z-index: 10;
	display: grid;
	grid-auto-columns: 1fr auto 1fr;
	grid-auto-rows: 1fr;
	gap: $space-lg;
	align-items: center;
	.Heading {
		grid-column: 1 / 3;
		@include breakpoint(medium) {
			grid-column: 2 / 3;
		}
		grid-row: 1 / 2;
	}
	.topBar-button {
		text-align: right;
		grid-column: 3 / 4;
		grid-row: 1 / 2;
	}
}
.midBar {
	background-color: lighten($brand-color-warm-black, 10%);
	@include colorReverse;
	@include inset-md;
	display: flex;
	justify-content: center;
	gap: $space-lg;
	a {
		text-decoration: underline;
	}
}
ul.styled {
	list-style-position: inside;
	margin-left: 0;
	padding-left: 1.5rem;
	text-indent: -1.5rem;
	@include text(3, loose);
	@include breakpoint(large) {
		@include text(4, loose);
	}
}
.largeText {
	@include text(4);
	@include breakpoint(large) {
		@include text(5);
	}
}
.mediumText {
	@include text(3);
	@include breakpoint(large) {
		@include text(4);
	}
}
.funnel {
	overflow: hidden;
	position: relative;
	@include breakpoint(large) {
		display: grid;
		grid-auto-columns: 1fr 1fr;
		grid-auto-rows: 1fr 1fr;
		align-items: center;
	}
	.text {
		padding: $space-md;
		margin-bottom: $space-lg;
		@include breakpoint(large) {
			grid-column: 1 / 2;
			margin-bottom: 0;
			margin-left: $space-xl;
			transform: translateX(-50%);
			&.right {
				grid-column: 2 / 3;
				margin-left: 0;
				margin-right: $space-xl;
				transform: translateX(50%);
			}
			grid-row: 1 / 3;
			padding: $space-xl;
			transition: all 0.6s ease;
			opacity: 0;
			&.animate {
				transform: translateX(0);
				opacity: 1;
			}
		}
		&.overlay {
			margin: $space-md;
			@include breakpoint(large) {
				margin: $space-xl;
			}
			background-color: $color-overlay-black;
			border-radius: $space-md;
			&.white {
				background-color: $color-overlay-white;
				@include dropShadow(3);
			}
		}
	}
	.images {
		display: flex;
		gap: $space-lg;
		align-items: center;
		@include inset-responsive;
		@include breakpoint(large) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
			transform: translateX(50%);
			&.left {
				transform: translateX(-50%);
				grid-column: 1 / 2;
			}

			transition: all 0.6s ease;
			opacity: 0;
			&.animate {
				transform: translateX(0);
				opacity: 1;
			}
		}
		img {
			max-height: 250px;
			@include breakpoint(medium) {
				max-height: 500px;
			}
		}
	}
	.credit {
		@include text(1);
		@include inset-sm;
		position: absolute;
		right: 0;
		bottom: 0;
		&.reverse {
			color: $color-text-muted-reverse;
		}
	}
	.ebirdCredit {
		@include text(2);
		font-weight: bold;
		@include inset-md;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: $color-white;
		display: flex;
		align-items: center;
		gap: $space-sm;
		img {
			height: 1.5rem;
		}
	}
	.mlCredit {
		@include text(2);
		font-weight: bold;
		@include inset-md;
		position: absolute;
		left: 0;
		bottom: $space-md;
		@include breakpoint(medium) {
			bottom: 0;
		}
		display: flex;
		flex-direction: column;
		gap: $space-sm;
		background-color: $color-white;
		img {
			height: 1.5rem;
		}
	}
	&.yellowlegs {
		padding-top: 0;
		background-color: #ffd147;
		@include breakpoint(large) {
			background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/yellowlegs_4.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
		@include breakpoint(large) {
			.text {
				width: 45%;
				margin: $space-xl;
				margin-right: 0;
			}
		}
		a {
			color: $color-black;
			text-decoration: underline;
		}

		@media screen and (min-width: 2000px) {
			// crazy wide
			min-height: 60vh;
		}
	}
	&.articles {
		padding-top: 0;
		background-color: #4c5b76;
		grid-auto-columns: 1fr 1.6fr;
		@include breakpoint(large) {
			margin-left: 0;
			.text.right {
				padding-right: 0;
			}
		}
		@include breakpoint(large) {
			background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/southernfulmar_2.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}
	&.maps {
		background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/blue_rock_thrush_map.jpg');
		background-position: center;
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: 100%;
		padding: 8rem 0;
		@include breakpoint(large) {
			padding: 0;
			min-height: 80vh;
			.overlay {
				width: 40%;
			}
		}
	}
	&.multimedia {
		background-color: $color-secondary-6;
		.text {
			margin-bottom: 7rem;
		}
		.spect {
			max-width: 300px;
		}
		@include breakpoint(large) {
			.text {
				margin-bottom: 0;
			}
			.spect {
				max-width: none;
			}
			background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/sunbird.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}
	&.taxonomy {
		background-color: $color-black;
		background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/taxonomy_bg_2.jpg');
		background-position: center;
		background-attachment: fixed;
		background-repeat: no-repeat;
		min-height: 80vh;
		background-size: 200%;
		@include breakpoint(medium) {
			background-size: 100%;
		}
		.text {
			margin-bottom: 0;
		}
		.wagtailSubspecies {
			max-height: 360px;
		}
	}
	&.global {
		background-color: #000;
		background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/earth_2.jpg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100%;
	}
}

.subscribe {
	@include inset-lg;
	@include breakpoint(medium) {
		@include inset-xl;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $color-primary-1;
	.cards {
		display: flex;
		gap: $space-lg;
		flex-direction: column;
		@include stack-lg;
		@include breakpoint(medium) {
			flex-direction: row;
		}
		.card {
			@include inset-squish-xl;
			@include dropShadow(2);
			background-color: $color-white;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: $space-md;
			text-align: center;
			.card-header {
				text-transform: uppercase;
				font-weight: bold;
				color: $color-text-muted;
			}
			.card-price {
				@include text(6);
				font-weight: bold;
			}
		}
	}
}

.howitworks {
	@include inset-responsive;
	@include colorReverse;
	background-color: $brand-color-forest-green-shade;
	display: flex;
	flex-direction: column;
	align-items: center;
	/*a {
		color: $brand-color-chartreuse-tint-2 !important;
		text-decoration-color: $brand-color-chartreuse-tint-2 !important;
		text-decoration-thickness: 0.5px;
		text-decoration: underline;
	}*/
	p {
		@include breakpoint(medium) {
			max-width: 40rem;
		}
	}
}

.previews {
	@include inset-responsive;
	background-color: $color-neutral-1;
}

$block: '.hex-grid';

@mixin grid-item($amount) {
	@for $i from 1 through $amount {
		&:nth-of-type(#{$amount}n + #{$i}) {
			grid-column: #{$i + $i - 1} / span 3;
			@if $i % 2 == 0 {
				grid-row: calc(var(--counter) + var(--counter) - 1) / span 2;
			}
		}
	}

	@for $i from 1 through 20 {
		&:nth-of-type(n + #{$i * $amount + 1}) {
			--counter: #{$i + 1};
		}
	}
}

#{$block} {
	display: flex;
	justify-content: center;

	&__list {
		--amount: 5;
		position: relative;
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: grid;
		grid-template-columns: repeat(var(--amount), 1fr 2fr) 1fr;
		grid-gap: $space-sm $space-md;
	}

	&__item {
		position: relative;
		grid-column: 1 / span 3;
		grid-row: calc(var(--counter) + var(--counter)) / span 2;
		height: 0;
		padding-bottom: 90%;
		@include text(3);
	}

	&__content {
		position: absolute;
		height: 100%;
		width: 100%;
		color: $color-white;
		font-weight: bold;
		background-color: rgba(223, 223, 223, 0.2);
		clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 25%;
		text-decoration: none;
		text-align: center;
	}
}

@media screen and (min-width: 1440px) {
	#{$block} {
		&__list {
			--amount: 5;
			--counter: 1;
		}

		&__item {
			@include grid-item(5);
			@include text(4);
		}
	}
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
	#{$block} {
		&__list {
			--amount: 5;
			--counter: 1;
		}

		&__item {
			@include grid-item(5);
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
	#{$block} {
		&__list {
			--amount: 5;
			--counter: 1;
		}

		&__item {
			@include grid-item(5);
		}
	}
}

@media screen and (min-width: 400px) and (max-width: 1023px) {
	#{$block} {
		&__list {
			--amount: 4;
			--counter: 1;
		}
		&__item {
			@include grid-item(4);
		}
	}
}

@media screen and (max-width: 399px) {
	#{$block} {
		&__list {
			--amount: 3;
			--counter: 1;
		}
		&__item {
			@include grid-item(3);
		}
	}
}
